body {
  overscroll-behavior: none;
}

.primary-heading {
  padding-top: 5rem;
  font-size: clamp(2rem, 5vw, 4rem);
  color: #ead067;
  line-height: 5rem;
  max-width: 1000px;
}

.home-background {
  height: 100vh;
  width: 100%;
  background: linear-gradient(45deg, #a2a2a2, #404040, #a2a2a2, #404040);
  background-size: 300% 300%;
  animation: color 12s ease-in-out infinite;
  overscroll-behavior: none;
}
@keyframes color {
  0%{
    background-position: 0 100%;
  }
  100%{
    background-position: 100% 0;
  }
}

.home-container {
  padding: 5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1.5s forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.home-banner-container {
  position: relative;
  display: flex;
  padding-top: 3rem;
}
.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.home-text {
  max-width: 600px;
  color: aliceblue;
  padding-top: 2rem;
}

@media screen and (max-width: 768px) {
  .primary-heading {
    padding-top: 5rem;
    font-size: clamp(2rem, 5vw, 3rem);
    color: #ead067;
    line-height: 2rem;
    max-width: 80%;
    margin-bottom: 20%;
  }

  .home-background {
    height: 100vh;
    width: 100%;
    background: #202020;
    background-size: 300% 300%;
    animation: none;
    overscroll-behavior: none;
  }
  
  .home-container {
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    font-size: smaller;
  }

  .home-text-section {
    align-items: center;
  }

  .home-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}