.contact-container {
padding: 5rem 2rem;
display: flex;
justify-content: space-between;
align-items: center;
max-width: 1200px;
margin: 0 auto;
}

.contact-info {
flex: 1;
max-width: 600px;
color: #fff;
}

.contact-info h1 {
font-size: clamp(2rem, 5vw, 3rem);
color: #ead067;
margin-bottom: 1rem;
}

.contact-info p {
margin-bottom: 2rem;
}

.contact-details {
display: flex;
justify-content: space-between;
margin-bottom: 2rem;
}

.contact-details div {
flex: 1;
margin-right: 2rem;
}

.contact-details h3 {
color: #ead067;
margin-bottom: 0.5rem;
}

.contact-details p {
color: #fff;
}

.contact-map {
flex: 1;
margin-left: 2rem;
}

.consultation-form {
flex: 1;
max-width: 400px;
}

.consultation-form h2 {
font-size: 2rem;
color: #ead067;
margin-bottom: 1.5rem;
margin-top: 8rem;
}

.consultation-form form {
display: flex;
flex-direction: column;
}

.consultation-form label {
color: #fff;
margin-bottom: 0.5rem;
}

.consultation-form input,
.consultation-form textarea {
padding: 0.5rem;
margin-bottom: 1rem;
}

.consultation-form button {
padding: 0.5rem 1rem;
background-color: #ead067;
color: #202020;
border: none;
cursor: pointer;
transition: background-color 0.3s ease-in-out;
}

.consultation-form button:hover {
background-color: #ffd700;
}

.phone-icon {
font-size: medium;
}

@media screen and (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    align-items: center;
  }

  .consultation-form h2 {
    margin-top: 0rem;
  }

  .contact-details {
    flex-direction: column;
    margin-bottom: 1rem;
  }
}