/* WebDesign.css */
.box ul li {
position: absolute;
width: 35px;
height: 35px;
list-style: none;
opacity: 0;
background: radial-gradient(rgb(140, 147, 149), transparent, transparent);
bottom: 0;
left: 10vw;
animation: animate 15s linear infinite;
}

.box ul li:nth-child(2){
left: 35vw;
animation-delay: 2s;
}
.box ul li:nth-child(3){
left: 25vw;
animation-delay: 4s;
}
.box ul li:nth-child(4){
left: 60vw;
animation-delay: 6s;
}
.box ul li:nth-child(5){
left: 75vw;
animation-delay: 4s;
}

@keyframes animate {
0%{
    transform: scale(0);
    opacity: 1;
    bottom: 0;
}
100%{
    transform: scale(10);
    opacity: 0;
    bottom: 100vh;
}
}

.wd-home-content {
display: flex;
align-items: center;
margin-top: 50px;
padding: 20px;
border-radius: 8px;
color: #fff;
margin-bottom: 20px;
text-align: justify;
}

.wd-home-image {
width: 50%;
height: auto;
border-radius: 8px;
margin-left: 4rem;
margin-right: 4rem;
}

.wd-container {
display: flex;
justify-content: space-between;
align-items: center;
max-width: 1200px;
margin: 0 auto;
opacity: 0;
transform: translateY(20px);
animation: fadeInUp 1.5s forwards;
}

.get-started-button-container {
display: grid;
justify-content: center;
align-items: center;
margin-bottom: 20px;
padding: 2rem;
}

.get-started-button {
display: grid;
justify-content: center;
align-items: center;
padding: 20px;
background-color: #ead067;
color: #202020;
text-decoration: none;
border-radius: 50px;
transition: background-color 0.3s ease-in-out;
}

.get-started-button:hover {
background-color: #ffffff;
}

@media screen and (max-width: 768px) {
  .wd-home-content {
    flex-direction: column;
    gap : 1rem;
    text-align: center;
    margin-top: 5px;
  }

  .wd-home-image {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}