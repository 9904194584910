
/* About Us */
.about-container {
display: grid;
justify-content: center;
align-items: center;
padding: 2rem 2rem;
max-width: 1200px;
margin: 0 auto;
}
.about-container h1 {
font-size: clamp(2rem, 5vw, 3rem);
color: #ead067;
margin-bottom: 2rem;
margin-top: 8rem;
}
.about-container h2 {
font-size: clamp(1rem, 4vw, 2rem);
color: #ead067;
margin-bottom: 2rem;
}

.about-image {
width: 100%; 
height: auto;
margin-right: 40px;
border-radius: 8px;
}

.about-content {
display: grid;
justify-content: center;
align-items: center;
color: #fff;
padding: 0rem 2rem;
margin-bottom: 3rem;
}
.about-content p {
font-size: 1rem;
line-height: 1.5;
display: grid;
justify-content: center;
align-items: center;
text-align: justify;
}
.about-columns {
display: flex;
gap: 2rem;
}
.about-image {
width: 50%; 
height: auto;
border-radius: 8px;
}

/* HireUs.css */
.hire-container {
padding: 5rem 2rem;
max-width: 1200px;
margin: 0 auto;
}

.hire-content {
display: grid;
justify-content: center;
align-items: center;
color: #fff;
text-align: center;
}
.hire-content p {
margin-bottom: 3rem;
}

.hire-container h2 {
font-size: clamp(1.5rem, 4vw, 2rem);
color: #ead067;
margin-bottom: 1rem;
}

.hire-icons {
display: flex;
gap: 2rem;
margin-right: 2rem;
padding: 2rem 0;
}

.hire-icons svg {
font-size: 1.5rem;
}

.hire-image {
width: 100%; 
height: auto;
border-radius: 8px;
}

/* Benefits.css */
.benefits-container {
max-width: 1200px;
margin: 0 auto;
padding: 5rem 2rem;
}

.benefits-content {
display: grid;
justify-content: center;
align-items: center;
color: #fff;
text-align: center;
}

.benefits-content p {
display: grid;
justify-content: center;
align-items: center;
}

.benefits-boxes {
display: flex;
justify-content: space-around;
gap: 2rem;
margin-bottom: 4rem;
}

.benefit-box {
background-color: #333; /* Add your desired background color */
padding: 1.5rem;
border-radius: 8px;
text-align: center;
min-width: 20rem;
min-height: 6rem;
max-width: 20rem;
}

.benefits-boxes h3 {
margin-bottom: 1rem;
}

.benefits-boxes p {
margin-bottom: 2rem;
}

.benefits-columns {
display: flex;
gap: 2rem;
text-align: justify;
}

.benefits-image {
width: 50%; 
height: auto;
border-radius: 8px;
}

@media screen and (max-width: 768px) {
  /* About Us */
  .about-container {
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    grid-template-columns: 1fr;
    gap: 1rem;
    overflow-x: hidden;
  }
  .about-container h1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }

  .about-columns {
    flex-direction: column;
    gap: 1rem;
  }
  .about-columns img {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 100%;
  }

  .about-content {
    text-align: center;
  }

  /* HireUs.css */
  .hire-container {
    flex-direction: column;
    align-items: center;
  }

  .hire-icons {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  /* Benefits.css */
  .benefits-columns {
    flex-direction: column;
    text-align: center;
    overflow-x: hidden;
  }

  .benefits-image {
    width: 100%;
    margin-bottom: 2rem;
  }

  .benefits-boxes {
    flex-direction: column;
    align-items: center;
  }
}