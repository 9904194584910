.services-container {
max-width: 1200px;
margin: 0 auto;
padding: 2rem 0rem;
}
.services-container h1 {
font-size: clamp(2rem, 5vw, 3rem);
color: #ead067;
margin-bottom: 2rem;
margin-top: 8rem;
}

.services-content {
display: flex;
justify-content: space-around;
align-items: center;
color: #fff;
flex-wrap: wrap;
}

.services-box {
background-color: #333;
padding: 2rem;
border-radius: 8px;
text-align: center;
margin: 1rem;
flex: 1 1 300px;
transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
cursor: pointer;
max-width: 50rem;
min-width: 40rem;
}

.services-box:hover {
transform: scale(1.05);
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle box-shadow on hover */
}

.services-box:hover::before {
content: '';
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(255, 255, 255, 0.2); /* Add a semi-transparent overlay on hover */
z-index: -1;
border-radius: 8px;
}

.services-box:hover h3 {
color: #ead067; 
}

.services-box h4 {
  color: #fff;
  margin-bottom: 1rem;
}

.services-box:hover h4 {
  color: #ead067; 
}  

.services-box:hover p {
color: #ccc; /* Change text color on hover */
}
.services-box h3 {
margin-bottom: 1rem;
}

.services-box p {
margin-bottom: 2rem;
}

.services-box svg {
margin-bottom: 1rem;
}

.services-box h3,
.services-box p {
color: #fff;
}

/* Add styles for the icon color if needed */
.services-box svg {
color: #fff;
}

.services-link {
text-decoration: none;
}

.construction:hover {
filter: brightness(1); /* Adjust brightness for tint effect */
}

.overlay {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
font-size: 20px;
background: rgba(0, 0, 0, 0.85); /* Semi-transparent background */
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
color: #fff;
border-radius: 8px;
opacity: 0;
transition: opacity 0.3s ease;
}

.construction:hover .overlay {
opacity: 1;
}

@media screen and (max-width: 768px) {

  .services-container {
    flex-direction: column;
    align-items: center;
    grid-template-columns: 1fr;
    gap: 1rem;
    overflow-x: hidden;
  }

  .services-container h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }

  .services-content {
    flex-direction: column;
    align-items: center;
  }

  .services-box {
    min-width: 20rem;
  }
}