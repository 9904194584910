* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Global Styles */
body {
  font-family: "Montserrat", sans-serif;
  background-color: #202020;
  overflow-x: none;
}

body::-webkit-scrollbar {
  display: none;
}
#about::-webkit-scrollbar{
  display: none;
}

/* Add the following media query for mobile view */
@media screen and (max-width: 768px) {

  /* Global Styles */
  body {
    font-size: 14px; /* Adjust the base font size for mobile */
    overflow-x: hidden;
  }
}