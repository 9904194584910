.footer-wrapper {
    margin: 6rem 5rem;
    display: flex;
    margin-top: 10rem;
  }
  .footer-logo-container {
    max-width: 110px;
  }
  .footer-icons {
    margin-top: 2.5rem;
  }
  .footer-icons svg {
    font-size: 1.5rem;
    margin-right: 1.25rem;
    color: #585858;
  }
   .footer-section-one {
    margin: 0.25rem 0rem;
    font-weight: 600;
    color: #4c4c4c;
  } 
  .footer-section-one p {
    margin-top: 2px;
  } 
  .footer-section-two {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  .footer-section-columns {
    display: flex;
    flex-direction: column;
    min-width: 190px;
  }
  .footer-section-columns span {
    margin: 0.25rem 0rem;
    font-weight: 600;
    color: #4c4c4c;
    cursor: pointer;
  }

  /* Add this @media query for screens smaller than 768px */
@media screen and (max-width: 768px) {
  .footer-wrapper {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: center; /* Center items horizontally on smaller screens */
    margin: 4rem 2rem; /* Adjust margin for better spacing on smaller screens */
    margin-top: 6rem; /* Increase margin-top for better spacing on smaller screens */
  }

  .footer-logo-container {
    margin-bottom: 2rem; /* Add margin at the bottom for better spacing on smaller screens */
  }

  .footer-section-two {
    justify-content: center; /* Center content horizontally on smaller screens */
    margin-top: 2rem; /* Add margin at the top for better spacing on smaller screens */
  }

  .footer-section-columns {
    min-width: auto; /* Remove minimum width on smaller screens */
    text-align: center; /* Center text horizontally on smaller screens */
    margin-bottom: 2rem; /* Add margin at the bottom for better spacing on smaller screens */
  }

  .footer-section-columns span {
    margin: 0.5rem 0; /* Adjust margin for better spacing on smaller screens */
  }
  
  .footer-icons {
    align-items: center;
    margin-top: 1rem; /* Adjust margin-top for better spacing on smaller screens */
  }

  .footer-icons svg {
    font-size: 1.2rem; /* Adjust icon size for better visibility on smaller screens */
    margin-right: 1rem; /* Adjust margin-right for better spacing on smaller screens */
  }
}
