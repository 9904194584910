.navbar {
padding: 1rem 2rem;
position: fixed;
width: 100%;
z-index: 1000;
backdrop-filter: blur(20px); 
}

.navbar-container {
display: flex;
justify-content: space-between;
align-items: center;
}

.navbar-logo { 
display: flex;
height: 100px;
}

.navbar-logo:hover {
cursor: pointer;
}

.menu-icon {
display: none;
color: #fff;
font-size: 1.5rem;
cursor: pointer;
}

.menu-icon.active {
transform: rotate(90deg);
}

.nav-menu {
list-style: none;
display: flex;
justify-content: space-between;
align-items: center;
}

.nav-item {
margin-right: 1rem;
}

.nav-links {
color: #fff;
text-decoration: none;
font-size: 1rem;
transition: color 0.3s ease-in-out;
cursor: pointer; 
}

.nav-links:hover {
color: #ead067;
}

  
  @media screen and (max-width: 768px) {
    .navbar {
      padding: 1rem 1rem;
    }
  
    .navbar-container {
      flex-direction: column;
      align-items: flex-start;
    }

    .menu-icon {
      display: block;
    }

    .nav-menu {
      display: grid;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 80px;
      left: -100%;
      width: 100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  }
  